const developmentApiDomain = "https://linebcdev.vespiario-system.com/vespathai/api";
const productionApiDomain = "https://linebc.vespiario-system.com/vespathai/api";

// PROD
export const liffId = "1654106772-bGdvgvDe"; // main oa
// export const liffId = "1654106772-kYXY7YMJ"; // deler oa

// DEV
// export const liffId = "1654185770-JGRL1KpD"; // main oa
// export const liffId = "1654185770-myndjrLq"; // deler oa

// LOCAL
// export const liffId = "1657538536-NGRMRDry";

const apiDomain = productionApiDomain;

export const isDealer = false

export const API = {
    CHECK_PROFILE: `${apiDomain}/profile/checkRegisterByUserId.aspx`,
    CREATE_NEW_USER: `${apiDomain}/profile/register.aspx`,
    UPDATE_USER: `${apiDomain}/profile/updateProfile.aspx`,
    GET_MODEL: `${apiDomain}/profile/getModel.aspx`,
    GET_BRANCH: `${apiDomain}/reward/getStoreAndService.aspx`,
    LOAD_MY_COUPON: `${apiDomain}/reward/loadMyCoupon.aspx`,
    LOAD_COUPON_DEALER: `${apiDomain}/rewarddealer/loadCouponListDealer.aspx`,
    LOAD_COUPON_LIST: `${apiDomain}/reward/loadCouponList.aspx`,
    LOAD_COUPON_LIST_JAN_2021_NEW1: `${apiDomain}/reward/loadCouponListJAN2021New1.aspx`,
    LOAD_COUPON_LIST_JAN_2021_NEW2: `${apiDomain}/reward/loadCouponListJAN2021New2.aspx`,
    LOAD_COUPON_LIST_JAN_2021_FOR_APR23: `${apiDomain}/reward/loadCouponListJAN2021ForAPR23.aspx`,
    LOAD_COUPON_LIST_MAY_2021_STEP1: `${apiDomain}/reward/loadCouponListMAY2021Step1.aspx`,
    PICK_UP_COUPON: `${apiDomain}/reward/pickupCoupon.aspx`,
    LOAD_PROFILE: `${apiDomain}/profile/loadDataProfile.aspx`,
    MY_INFO: `${apiDomain}/profile/myinfo.aspx`,
    REDEEM_COUPON: `${apiDomain}/reward/activateCoupon.aspx`,
    REDEEM_COUPON_FILL_ADDRESS: `${apiDomain}/reward/activateCouponFillAddress.aspx`,
    REDEEM_RANDOM_COUPON: `${apiDomain}/reward/activateCouponFillAddressCouponLimit.aspx`,
    CHECK_ENGINE_NUMBER: `${apiDomain}/reward/checkEngineNumber.aspx`,

    LOAD_RANDOM_COUPON: `${apiDomain}/reward/loadCouponRandomList.aspx`,
    LOAD_RANDOM_COUPON_DEALER: `${apiDomain}/rewardDealer/loadCouponRandomListDealer.aspx`,
    PICK_UP_COUPON_RANDOM: `${apiDomain}/reward/pickupCouponRandom.aspx`,
    REDEEM_COUPON_RANDOM: `${apiDomain}/reward/activateCouponFilLAddressCouponLimit.aspx`,

    PICK_UP_COUPON_19_JUL: `${apiDomain}/reward/pickupCoupon19JUL2021.aspx`,
    ACTIVATE_COUPON_19_JUL: `${apiDomain}/reward/activateCoupon19JUL2021.aspx`,

    TRACKER: `${apiDomain}/tracker/ClickTrack.aspx`,
}